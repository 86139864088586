import React from "react";
import styles from "./Footer.module.scss";

const Footer = (props: any) => {
  return (
    <div className={styles.footer}>
      © <span id="current-year"></span> S&P Global All rights reserved.
      Reproduction in whole or in part without permission is prohibited.
      
    </div>
  );
};

export default Footer;
