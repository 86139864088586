import React, { useState } from 'react';
import {getBackEndURI} from '../../../api'
import styles from "./MainView.module.scss";
const MainView = () => {
    const apiUrl = import.meta.env.VITE_API_URL;
    console.log(apiUrl)
    
    return (
        <div className={styles.parent}>
            {apiUrl} 
        </div>
    );
};

export default MainView;
