
import React from 'react';
import styles from "./Header.module.scss";
import logo from "./header_icon.svg"

const Header = (props: any) => {
    return (
        <header className={styles.header} role={"banner"}>
            <img className={styles.headerLogo} height="30" width="18" src={logo} alt="" />
            <span>Fleet Intelligence</span>
        </header>
    );
};

export default Header;
