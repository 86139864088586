import React from 'react';
import { Outlet } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import styles from "./Layout.module.scss";
import Header from "./Header/Header";
import Footer from './Footer/Footer';
import MainView from './MainView/MainView';

const Layout = () => {
    const openRouteInNewTab = (path: string) => {
        window.open(path, "_blank");
    };
    
    return (
        <div className={styles.layout}>
                <Header />
                <MainView/>
                <Footer/>
        </div>
    );
};

export default Layout;
