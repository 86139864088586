import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from './pages/layout/Layout';

export default function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                </Route>
            </Routes>
        </HashRouter>
    );
}
